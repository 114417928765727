import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SideMenuService} from "../../../services/side-menu.service";
import {ClaimService} from "../../../services/claim.service";
import {ClaimsBaseComponent} from "../../claim-base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UtilitiesService} from "../../../utilities/utilities.service";
import {UIBankService} from '../../../ui/ui-bank.service';
import {UiOptionsModel} from '../../../ui/ui-options.model';
import {UiComponent} from '../../../ui/ui.component';
import { TransactionInfo } from 'src/app/model/transaction-info.model';
import { TransactionInfoService } from 'src/app/services/transaction-info.service';
import { InsuredPerson } from 'src/app/model/insured-person.model';
import { ClaimTypes } from 'src/app/model/claim-type.model';

@Component({
    selector: 'app-bank-account',
    templateUrl: './bank-account.component.html',
    styleUrls: ['./bank-account.component.css']
})
export class BankAccountComponent extends ClaimsBaseComponent implements OnInit {
    showTotalBox = false;
    bankAccountForm: FormGroup;
    isMotorLodgedBy: string = '';
    isInternetExplorer;
    @ViewChild('PYM03', {static: false, read: UiComponent}) uiBankNameField: UiComponent;
    currentLanguage: string;
    bankListEnglish: any[];
    bankListChinese: any[];
    transactionInfo : TransactionInfo;
    bankSelectImg: string;
    bankSelected: string = "bankcomm";
    bankDescription: string;
    accountNameDirectList: UiOptionsModel[] = [];
    country: string;
    showBranchCodeInstruction: boolean = false;
    showBranchCodeMsg: boolean = true;
    bankService: UIBankService = new UIBankService();

    constructor(private fb: FormBuilder,
                private claimService: ClaimService,
                private activatedRoute: ActivatedRoute,
                public sideMenuService: SideMenuService,
                private modalService: NgbModal,
                private transactionInfoService : TransactionInfoService,
                private router: Router,
                private injector : Injector) {

        super(injector);
        this.transactionInfo  = this.transactionInfoService.getTransactionInfo();
        this.claim.setPaymentRefundToBank("Y");
        this.country = this.claim.getCountry();
        if(this.currentLanguage == 'zh'){

            this.translate.getTranslation("en").subscribe(res => {
                this.bankListEnglish = res['bankList'];
            }); 

            this.translate.getTranslation("zh").subscribe(res => {
                this.bankListChinese = res['bankList'];
            });
        }
    }

    ngOnInit() {

        this.pushGABankAccountView();
        this.currentLanguage = this.translate.currentLang;
        this.populateAccountNameList();

        this.bankAccountForm = this.fb.group(
            {
                accountNameDirect: [UtilitiesService.truncateValue(this.claim.getAccountNameDirect(), 80)],
                bankDirect: [this.claim.getBankDirectDesc()],
                branchCode:  [this.claim.getBranchCode()],
                accountNumberDirect: [this.claim.getAccountNumberDirect()],
                bankSelectImg: [this.bankSelected]
            }
        );  
        
      // detect if it is an Internet Explorer browser.
      let IEDetected = this.detectIE();
      if(IEDetected === false){
        //console.log("IS NOT BOTH!");
        this.isInternetExplorer = false;
      }
      else if(IEDetected >= 12){
        //console.log("IS EDGE!");
        this.isInternetExplorer = false;
      } else {
        //console.log("IS INTERNET EXPLORER!");
        this.isInternetExplorer = true;
      }

      this.setBankBranchCodeImage();

    }

    ngAfterViewInit() {

        this.bankAccountForm.valueChanges.subscribe(data => {
            this.claim.setAccountNameDirect(super.getValueInComponent('accountNameDirect'));
            this.claim.setBranchCode(this.bankAccountForm.get('branchCode').value);
            this.claim.setAccountNumberDirect(this.bankAccountForm.get('accountNumberDirect').value);
            this.claim.setBankDirect(this.getValueInComponent('bankDirect'));
            this.claim.setBankDirectDesc(this.getTextInComponent('bankDirect'));

            setTimeout(() => {
                this.setBankBranchCodeImage();
            }, 100);

        });
    }

    setBankBranchCodeImage(){
        let selectedBank : string = this.claim.getBankDirect();
        this.showBranchCodeInstruction = false;
        if(this.isNotNullOrUndefinedStr(selectedBank) && this.country !== "mys"){
            let imagePath;
            let bankDesc;
            let ctry = this.country?.toLowerCase();
            if (ctry === "sgp"){
                selectedBank = selectedBank.substr(0, 4) + selectedBank.substr(7, 1).toLowerCase();
            } else if (ctry === "hkg" || ctry === "hgi")  {
                selectedBank = selectedBank.substr(0, 3);
            }
            selectedBank = this.bankService.getBranchInstructionCode(selectedBank, ctry);
            if(this.isNotNullOrUndefinedStr(selectedBank)){
                imagePath = 'home.howToFindBranchCode.branchCodeImage.'+selectedBank;
                bankDesc = 'home.howToFindBranchCode.branchCodeDescription.'+selectedBank;
                this.setBankCodeImage(imagePath, bankDesc);
            }
        }
    }

    setBankCodeImage(imagePath: string, bankDesc: string){
        this.translate.stream(imagePath).subscribe((res:string) => {
            this.bankSelectImg = res;
        });

        this.translate.stream(bankDesc).subscribe((res:string) => {
            this.bankDescription = res;
        });
        this.showBranchCodeInstruction = true;
    }

    showBranchCodeMessage(show: boolean) {
        this.showBranchCodeMsg = show;
    }


    showModal() {
        this.showTotalBox = true;
    }
    getData(msg) {
        this.showTotalBox = msg;
    }

    goToNext() {
        if (this.claim.getServiceUnit() === 'GP' || (this.claim.getServiceUnit() !== 'GP' &&
            this.validateForm() && this.validateBankInput())) {
            this.router.navigate(["/claimform/paymentAndContact/provideContactInfo"], {
                relativeTo: this.activatedRoute
            });
        }
    }

    back() {
        if(this.transactionInfo.isMotorProduct() && this.transactionInfo.getCurrentClaimType() == 'CLAIM_TYPE_MVA_WINDSCREEN'){
            this.router.navigate(["/claimform/windscreen/supportDoc"], {
                relativeTo: this.activatedRoute
            });
        } else {
            let currentSelectedClaimType = this.transactionInfo.getCurrentClaimType();
            let url = '/claimform/anotherInsurance';
            if (this.claim.getCountry().toLowerCase() === 'sgp' && this.transactionInfo.isAccidentProduct()) {
                if (ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_ACCIDENTAL_MEDICAL_EXPENSES] === currentSelectedClaimType) {
                    url = '/claimform/accidentalMedicalExpenses/supportDoc';
                } else if (ClaimTypes[ClaimTypes.CLAIM_TYPE_PA_OTHERS] === currentSelectedClaimType) {
                    url = '/claimform/paOthers/supportDoc';
                }
            }
            this.router.navigate([url], {
                relativeTo: this.activatedRoute
            });
        }
    }

    validateForm(): boolean {
        return super.validateForm(this.bankAccountForm);
    }

    validateBankInput() : boolean {

        let isValidBank: boolean = false;

        let bankNameInput =  this.getValueInComponent('bankDirect');
        let bankNameInputDesc = super.getTextInComponent('bankDirect');

        // The regex pattern must be removed along with the characters that comes before it because this is the part where the polyfill
        //activates when searching for the substring on IE.
        var regexPattern = /.*\#\#\#\[P0LYFlLLed\]\#\#\#/;

        //console.log('BankNameInput: '+bankNameInput);
        if (this.isNotNullOrUndefinedStr(bankNameInput)) {
           bankNameInput = bankNameInput.replace(regexPattern, '');
           this.claim.setBankDirect(bankNameInput);
         }

        if (this.isNotNullOrUndefinedStr(bankNameInputDesc)) {
            bankNameInputDesc = bankNameInputDesc.replace(regexPattern, '');
            this.claim.setBankDirectDesc(bankNameInputDesc);
        }

        //validate bank name from the drodpwon
        let bankOptions: UiOptionsModel[];
        if (this.country?.toLowerCase() === "sgp") {
            bankOptions = this.bankService.getBankOptionsSgp();
        } else if (this.country?.toLowerCase() === "mys") {
            bankOptions = this.bankService.getBankOptionsMys();
        } else {
            bankOptions = this.bankService.getBankOptions();
        }

        for (var i = 0; i < bankOptions.length;  i++) {
            let labelText =  this.translate.instant(bankOptions[i]['label']);
            let bankOptionText = labelText;
            if (!UtilitiesService.isNullOrUndefined(bankNameInput) && bankOptionText.toLowerCase() == bankNameInput.toLowerCase()) {
                isValidBank = true;
            }
        }

        if (!isValidBank) {
            this.uiBankNameField.error("invalidBank");
            //throw new Error("Error Bank Name Not Found in the list");
        } else{
            const self = this;
            if(self.currentLanguage == 'zh'){
                for (var prop in self.bankListChinese) {
                  if (self.bankListEnglish.hasOwnProperty(prop)) { 
                    if(self.claim.getBankDirect() == self.bankListChinese[prop]){
                        self.claim.setBankDirect(self.bankListEnglish[prop].toString());
                        self.claim.setBankDirectDesc(self.claim.getBankDirectDesc());
                    }
                  }
                }

            } else if(self.currentLanguage == 'en'){
                self.claim.setBankDirect(self.claim.getBankDirect());
                self.claim.setBankDirectDesc(self.claim.getBankDirectDesc());
            }
        }

        return isValidBank;
    }

    goToCheque() {
        this.pushGASwitchCheque();
        this.router.navigate(["/claimform/paymentAndContact/sendCheque"], {
            relativeTo: this.activatedRoute
        });
    }

    goToOverseasBank() {
        this.pushGASwitchOverseasBank()
        this.router.navigate(["/claimform/paymentAndContact/payOverseasBank"], {
            relativeTo: this.activatedRoute
        });
    }

    //START - Google Analytics
    pushGABankAccountView() {
        let gaStepNum = this.getGaStep();
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
            'pageStep': 'Payment - Bank Account Details',
            'vPath': '/payment-bank-account-details',
            'event': 'vpageview',
            'ecommerce': {
                'checkout': {
                    'actionField': {'step': gaStepNum, 'option': 'Bank'} 	//Step7-Payment
                }
            }
        });
    }

    getGaStep(): number {
        if (this.transactionInfo.isGenericClaim()) {
            return 5;
        } else if (this.transactionInfo.isHomeProduct()) {
            return 6;
        } else if (this.transactionInfo.isAccidentProduct()) {
           return !this.claim.getClaimPersonalAccident().getHasEligibleClaimTypes() ?  7 : 8;
        }
        return 7;
    }

    pushGABankAccountFill() {
        (<any>window).dataLayer.push({'event': 'editprefill'});
    }

    pushGASwitchCheque() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': 'switchtocheque'});
    }

    pushGASwitchOverseasBank() {
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({'event': 'switchtooverseasbank'});
    }

    //END - Google Analytics

    open(content, name) {
        this.modalService.open(content, {size: 'lg'});
    }

    detectIE() {
        var ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    applyP400Format(event: any, fieldName: string) {
        let newStr = UtilitiesService.p400Format(event.target.value);
        event.target.value = UtilitiesService.p400Format(event.target.value);

        if(fieldName == 'accountNameDirect'){
            this.claim.setAccountNameDirect(newStr);
            this.bankAccountForm.get(fieldName).setValue(newStr);
        }
    }
      
    populateAccountNameList() {

        if (this.transactionInfo.isMotorProduct()) {
            this.isMotorLodgedBy =  this.claim.getClaimMotor().getIsLodgeByPolicyHolder() ? "customer" : "repairer";
        } else if (this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isTravelProduct()) {
           let policyholder = UtilitiesService.truncateValue(this.claim.getPolicyHolderName(), 80);
           const policyHolderOption = new UiOptionsModel(policyholder, policyholder);
           if (this.transactionInfo.isDomesticHelperProduct() || this.transactionInfo.isTravelPersonal()) {
                this.accountNameDirectList.push(policyHolderOption);
           }
           if (this.transactionInfo.isTravelProduct()) {
                if (this.transactionInfo.isTravelCorporatePolicyHolder()) {
                    this.accountNameDirectList.push(policyHolderOption);
                } else {
                    let storedInsuredPersonLists: InsuredPerson[] = this.transactionInfo.getInsuredPersonsList();
                    if (!UtilitiesService.isNullOrUndefined(storedInsuredPersonLists)  && storedInsuredPersonLists.length > 0) {
                        for (var i = 0; i < storedInsuredPersonLists.length; i++) {
                            let insuredPerson = storedInsuredPersonLists[i].getInsuredName();
                            if (storedInsuredPersonLists.find(a => a.valueOf() === insuredPerson) == undefined) {
                                insuredPerson =  UtilitiesService.truncateValue(insuredPerson, 80);
                                let insuredPersonOption = new UiOptionsModel(insuredPerson, insuredPerson);
                                this.accountNameDirectList.push(insuredPersonOption);
                            }
                        }
                    }
                }
            }
        }
    }
}
